/*
	General styles
*/

body, html {
	height: 100%
}
.touchonly {
	display: none;
}
html.touch .touchonly {
	display: block;
}
.noselect * {
	user-select: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
}
.doselect * {
	user-select: auto;
	-webkit-user-select: auto;
	-khtml-user-select: auto;
	-moz-user-select: auto;
	-o-user-select: auto;
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

html[xmlns] .clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}
